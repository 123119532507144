<div class="not-completed-container">
  <p class="text-center font-bold">Your review is <span class="text-danger">NOT COMPLETE</span></p>
  <div *ngIf="data.title !== 'ncc'">
    <p class="font-bold text-center">You must enter either Yes, No Or {{ data.title }} for each row in the list</p>
    <p class="font-bold text-center"> To view records that are not complete, select the Return to Review button below
      and then select the Uncompleted
      button</p>
  </div>
  <div *ngIf="data.title === 'ncc'">
    <p class="font-bold text-center" *ngIf="data.title === 'ncc'">You must enter either Approve Or Remove for each row
      in the list</p>
    <p class="font-bold text-center"> To view records that are not complete, select the "Return to Review" button below
      and then select the "View Uncompleted Records"
      button</p>
  </div>
  <div class="flex justify-center items-center">
    <button class="review-button mr-2" (click)="returnToView()"> Exit Review</button>
    <button class="review-button ml-2" (click)="returnToView()"> Return to Review</button>
  </div>
</div>
