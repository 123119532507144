<div class="page-margin">
  <div class="container-fluid content_container pt-2">
    <div class="row">
      <div class="col-2">
        <img class="ford-icon" src="/assets/ford.png" alt="Purchasing Annual Access Review System"/>
      </div>
      <div class="col-10">
        <div class="title-alignment row">
          <p class="mb-0 font-21">
            Purchasing & Associated Systems Annual Access Review Manager Home Page
          </p>
          <p class="mb-0 font-bold"><span *ngIf="date !== ''">(Access status as of {{ date | date:'MMM d, y' }})</span>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 ">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-10 header-button font-family">
                        <span><button type="submit" class="btn xml-button mb-1" (click)="instructions()">
                            Instructions</button></span>&nbsp;
            <span><button type="submit" class="btn xml-button mb-1" (click)="systemsList()">
                            System List</button></span>&nbsp;
            <span><button type="submit" class="btn xml-button mb-1" (click)="systemDesc()">
                            System Descriptions</button></span>&nbsp;
            <span><button type="submit" class="btn xml-button mb-1" (click)="ciriticalInfo()">
                                Critical Information on CPARS, PartSpec/Boss90 and CORA</button></span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="content-align">
              <tr class="font-family">
                <td class="tr">
                                    <span><button type="submit" class="btn xml-button mb-1" (click)="submitRecords()">
                                        SUBMIT your response and exit the screen</button></span>&nbsp;
                  <span><button type="submit" class="btn xml-button mb-1" (click)="saveRecords()">
                                        SAVE   your response and remain on the screen</button></span>&nbsp;
                  <span><button type="submit" class="btn xml-button mb-1" (click)="viewUncompletedRecords()">
                                        View UNCOMPLETED records</button></span>&nbsp;
                </td>
              </tr>
              <tr>
                <td class="td-content">
                  <b><h6 class="td-font-size indent-left">Approval of users indicates you accept responsibility for the
                    access you
                    are authorizing including the user's ability to appropriately and effectively
                    utilize the access they have been granted and, if applicable, the user's
                    delegation of authority. Audit requirements stipulate if there are any changes to
                    the user's job role prior to the next Annual Access Review, the appropriate access
                    form must be completed and forwarded according to the directions on the form.</h6></b>
                </td>
              </tr>
              <tr>
                <td class="td-content indent-left"><i>To <u><b>SEARCH</b></u> for a cdsid and/or system in your records
                  use the search boxes
                  provided and click Search.</i><br/></td>
              </tr>
              <tr>
                <td class="td-content">
                  <form [formGroup]="searchGroup" (ngSubmit)="searchRecords()">
                                        <span class="indent-left"><label> CDS ID
                                            <input type="text" formControlName="cdsId" class="input-text mb-1">
                                        </label></span> &nbsp;
                    <span><label>System
                                            <input type="text" formControlName="system" class="input-text mb-1">
                                        </label></span> &nbsp;
                    <span><label><button type="submit" class="btn xml-button mb-1">
                                            Search</button> To redisplay ALL RECORDS click on the Search button.</label></span>&nbsp;
                  </form>
                </td>
              </tr>
              <tr>
                <td class="td-content">
                  <i class="indent-left">To search for a CDS ID in Super Duper CDS, click
                    <a (click)="openSuperCDS($event)" href="#"><b>HERE</b></a>.</i>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row td-content">
          <span class="indent-left"> &nbsp; &nbsp; *NCM - Not Current Manager</span>
        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <form [formGroup]="userAccessDetails">
              <table class="content-align table">
                <thead>
                <tr>
                  <th colspan="2" class="table-th">
                    <p>&nbsp;Approve&nbsp;</p>
                    <span><td>Yes&nbsp;</td></span>
                    <span><td>&nbsp;No</td></span>
                  </th>
                  <th class="table-th">
                    <div>NCM*</div>
                  </th>
                  <th class="table-th">
                    <div>Current Manager</div>
                  </th>
                  <th class="table-th">
                    <div>CDS ID</div>
                  </th>
                  <th class="table-th">
                    <div>User Name</div>
                  </th>
                  <th class="table-th">
                    <div>RACF ID</div>
                  </th>
                  <th class="table-th">
                    <div>System</div>
                  </th>
                  <th class="table-th">
                    <div>Job Function</div>
                  </th>
                  <th class="table-th">
                    <div>System Access</div>
                  </th>
                  <th class="table-th">
                    <div>Div Plt</div>
                  </th>
                  <th class="table-th">
                    <div>Delegation (USD)</div>
                  </th>
                  <th class="table-th">
                    <div>Comments (250 Chars)</div>
                  </th>
                </tr>
                </thead>
                <tbody formArrayName="records" *ngFor="let user of records.controls;let i=index;">
                <tr [formGroupName]="i">
                  <td class="td-border">
                    <div><input type="radio" formControlName="approve" (change)="disableNcm(user)"
                                value="Y"></div>
                  </td>
                  <td class="td-border">
                    <div><input type="radio" formControlName="approve" (change)="disableNcm(user)"
                                value="N"></div>

                  </td>
                  <td class="td-border">
                    <div><input type="radio" formControlName="approve" (change)="enableNcm(user.get('cdsId')?.value)"
                                value="NCM"></div>
                  </td>
                  <td class="td-border">
                    <div><input class="input-text pl-1" formControlName="currentManager"></div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('cdsId')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('name')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('racfId')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('system')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('jobFunction')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('systemAccess')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('divPlt')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div>{{ user.get('delegation')?.value }}</div>
                  </td>
                  <td class="td-border">
                    <div><textarea class="input-text-area" formControlName="comments"></textarea></div>
                  </td>
                </tr>
                </tbody>
                <tbody *ngIf="notReviewed">
                <tr>
                  <td colspan="12" class="text-center font-bold">No Records Found</td>
                </tr>
                </tbody>
              </table>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!hasRecords && (notReviewed !== true)">
      <div class="col-12">
        <table class="no-records">
          <tr>
            <td class="no-records-padding">
              You do not have any records to approve.<br>
              Please close the browser page to leave the application.<br><br>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div>
