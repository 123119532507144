import {Component, inject} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-review-not-completed',
  templateUrl: './review-not-completed.component.html',
  styleUrls: ['./review-not-completed.component.scss']
})
export class ReviewNotCompletedComponent {
  data = inject(DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<ReviewNotCompletedComponent>);
  
  returnToView(){
    this.dialogRef.close()
  }
}
